import { SET_COMBINATION, SET_REQUIRED_YEAR } from './-mutation-types';
import { usePointsStore } from '~/stores/points';
import PointsRequiredYearService from '~/services/api/crm/PointsRequiredYearService';
import PointsCombinationService from '~/services/api/crm/PointsCombinationService';
import { type PointsCombination } from '~/models/PointsCombination';
import { type PointsRequiredYear } from '~/models/PointsRequiredYear';

const Actions = {
    async fetchCombination(peCombinationId: number) {
        const pointsStore = usePointsStore();

        const pointsCombinationService = new PointsCombinationService();
        const response = await pointsCombinationService.fetchPointsCombination(peCombinationId);

        pointsStore[SET_COMBINATION](response);
    },
    async fetchRequiredYear(peRequiredYearId: number) {
        const pointsStore = usePointsStore();

        const pointsRequiredYearService = new PointsRequiredYearService();
        const response = await pointsRequiredYearService.fetchPointsRequiredYear(peRequiredYearId);

        pointsStore[SET_REQUIRED_YEAR](response);
    },
    setCombination(peCombination: PointsCombination) {
        const pointsStore = usePointsStore();
        pointsStore[SET_COMBINATION](peCombination);
    },
    setPeriod(peRequiredYear: PointsRequiredYear) {
        const pointsStore = usePointsStore();
        pointsStore[SET_REQUIRED_YEAR](peRequiredYear);
    },
};

export default Actions;

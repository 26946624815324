import { SET_COMBINATION, SET_REQUIRED_YEAR } from './-mutation-types';
import { usePointsStore } from '~/stores/points';
import { type PointsCombination } from '~/models/PointsCombination';
import { type PointsRequiredYear } from '~/models/PointsRequiredYear';

const Mutations = {
    [SET_COMBINATION](payload: PointsCombination | null) {
        const pointsStore = usePointsStore();
        pointsStore.peCombination = payload;
    },
    [SET_REQUIRED_YEAR](payload: PointsRequiredYear | null) {
        const pointsStore = usePointsStore();
        pointsStore.peRequiredYear = payload;
    },
};

export default Mutations;

/* eslint-disable vue/sort-keys */

import { defineStore } from 'pinia';
import { DefaultState } from './points/state';
import Getters from './points/getters';
import Actions from './points/actions';
import Mutations from './points/mutations';

export const usePointsStore = defineStore(
    'points',
    {
        state: DefaultState,
        getters: Getters,
        actions: {
            ...Actions,
            ...Mutations,
        },
    },
);

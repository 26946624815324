import { GET_COMBINATION, GET_REQUIRED_YEAR } from './-getter-types';
import { usePointsStore } from '~/stores/points';
import { type PointsCombination } from '~/models/PointsCombination';
import { type PointsRequiredYear } from '~/models/PointsRequiredYear';

const Getters = {
    [GET_COMBINATION]: (): PointsCombination | null => {
        const pointsStore = usePointsStore();

        return pointsStore.peCombination;
    },
    [GET_REQUIRED_YEAR]: (): PointsRequiredYear | null => {
        const pointsStore = usePointsStore();

        return pointsStore.peRequiredYear;
    },
};

export default Getters;

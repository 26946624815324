import { type PointsCombination } from '~/models/PointsCombination';
import { type PointsRequiredYear } from '~/models/PointsRequiredYear';

export type PointsState = {
    peRequiredYear: PointsRequiredYear | null;
    peCombination: PointsCombination | null;
}

export const defaultStateData = (): PointsState => ({
    peCombination: null,
    peRequiredYear: null,
});

export const DefaultState = (): PointsState => defaultStateData();

export type State = ReturnType<typeof DefaultState>
